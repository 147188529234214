@import './button.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

/* ========== RESETS - START =========*/
html {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

*,
*:before,
*:after {
	-webkit-box-sizing: inherit;
	-moz-box-sizing: inherit;
	box-sizing: inherit;
}

/* ========== RESETS - END =========*/

/* =============== NON-SCROLLABLE MOBILE/IOS FULL SCREEN - START =========== */
/* IOS BOTTOM NAVIGATION WILL ALWAYS SHOW */
/* ================================================================= */
html,
body,
    /* nextjs application wrappers */
#__next,
#__next > div,
    /* storybook wrappers */
#root,
#root > div {
	height: 100%;
}

body {
	/* dev */
	/*border: solid 5px lime;*/
	/*position: fixed;*/
	/*overflow: hidden;*/
	/*width: 100%;*/
}

/* =============== NON-SCROLLABLE MOBILE/IOS FULL SCREEN - END =========== */

.hero {
	width: 100%;
	color: #333;
}

.title {
	margin: 0;
	width: 100%;
	padding-top: 80px;
	line-height: 1.15;
	font-size: 48px;
}

.title,
.description {
	text-align: center;
}

.webcam {
	/* dev */
	/*background-color: #2C1C96;*/

	height: 100%;
	position: relative;
	overflow: hidden;
}

.webcam > video,
.webcam > img {
	position: absolute;

    /* mirror the output */
    transform: scaleX(-1);

	top: 0;
	bottom: 0;
	margin-top: auto;
	margin-bottom: auto;

	/* try to preserve aspect ratio */
	object-fit: cover;
	height: 100%;
	width: 100%;
}
